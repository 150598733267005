/** @jsx jsx */
import { jsx } from "theme-ui"

export default function Body({children}) {
  return (
    <p
      sx={{
        fontFamily: "body",
        color: "text",
      }}
    >
      {children}
    </p>
  )
}

