/** @jsx jsx */
import { jsx } from "theme-ui"
import { css } from "@emotion/core"
import Header from "../components/header"
import Body from "../components/body"

export default function Theme() {
  return (
    <div sx={{ margin: 4 }}>
      <Header>Convergence</Header>
      <Body>Paragraph text... Quia vero enim aut labore maxime nisi et. Inventore perferendis asperiores exercitationem odit corporis debitis. Vero voluptas et eaque ad voluptatem voluptate deserunt et. Quia et pariatur molestiae occaecati explicabo dolores quo distinctio.</Body>
      <Body>Second paragraph... Labore maxime nisi et. Inventore perferendis asperiores exercitationem odit corporis debitis. Vero voluptas et eaque ad voluptatem voluptate deserunt et. Quia et pariatur molestiae occaecati explicabo dolores quo distinctio.</Body>
      <Swatch label="primary" reverse />
      <Swatch label="secondary" reverse />
      <Swatch label="text" reverse />
      <Swatch label="muted" />
      <Swatch label="background" />
    </div>
  )
}

function Swatch({ label, reverse }) {
  return (
    <div
      sx={{
        backgroundColor: label,
        padding: 4,
        marginBottom: 2,
      }}
      css={css`
        max-width: 300px;
        color: ${reverse ? "white" : "black"};
        border: ${reverse ? "none" : "1px dotted #444"}
      `}
    >
      {label}
    </div>
  )
}
